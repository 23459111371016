<template>
    <Layout>
        <!-- <h4 class="mb-1 font-size-18">
                {{ $t(`asset logs`) }}
                <span
                    class="ms-1 badge bg-gradient"
                    :class="{
                        'bg-primary': $route.params.role == 'player',
                        'bg-danger': $route.params.role == 'agent',
                    }"
                    >{{ $t($route.params.role) }}</span
                >
                <span class="ms-1 badge bg-dark bg-gradient">{{
                    $t($route.params.column)
                }}</span>
            </h4> -->
        <MoneyManagementTabs />
        <div class="card data">
            <div class="card-header bg-light bg-soft filters">
                <!-- <b-form inline> -->
                    <table
                        class="table table-bordered table-nowrap mb-0 table-form">
                        <tbody>
                            <tr>
                                
                                <td class="text-center hidden">
                                    {{ $t('username') }}
                                </td>
                                <td class="bg-white">
                                    <b-form @submit.prevent="initList(1)">
                                        <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`enter username`)"
                                        v-model="filters.search" />
                                    </b-form>
                                </td>
                                 <td class="text-center hidden">
                                    {{ $t('type') }}
                                </td>
                                <td class="bg-white" style="width: 15%">
                                    <multiselect
                                        :placeholder="`${$t('select type')}`"
                                        v-model="filters.type"
                                        :options="type_options">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <span class="flex-fill ps-2">{{
                                                $t(value.value)
                                            }}</span>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            {{ $t(option.value) }}
                                        </template>
                                    </multiselect>
                                </td>

                                <td class="text-center hidden">
                                    {{ $t('date') }}
                                </td>
                                <td class="bg-white">
                                    <div class="d-flex gap-1">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            :locale="$i18n.locale=='ko'?koLocale:null"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            :locale="$i18n.locale=='ko'?koLocale:null"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info flex-grow-1"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success flex-grow-1"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>
                                 <td class="bg-white">
                                    <div class="form-check-success w-100">
                                        <b-form-checkbox
                                            v-model="filters.with_bets"
                                            name="with_bets">
                                            {{ $t('include betting history') }}
                                        </b-form-checkbox>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="d-flex gap-1">
                                        <button
                                            :class="`btn btn-info ${
                                                loading ? 'disabled' : ''
                                            }`"
                                            @click="initList(1)">
                                            {{ $t('search') }}
                                        </button>
                                        <button
                                            :class="`btn btn-danger ${
                                                loading ? 'disabled' : ''
                                            }`"
                                            @click="resetFilter()">
                                            {{ $t('reset') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                <!-- </b-form> -->
            </div>
            <div class="card-body">
                <multiselect
                    style="width: 120px"
                    class="multiselect-sm me-0"
                    :placeholder="`${$t('limit')}`"
                    v-model="filters.limit"
                    :options="[50, 100, 500, 1000]"
                    :preselect-first="true"
                    :can-deselect="false"
                    :can-clear="false">
                    <template v-slot:singlelabel="{ value }">
                        <span class="mx-auto">{{
                            $t('view') + ' ' + value.value
                        }}</span>
                    </template>
                </multiselect>
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="8"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered table-striped"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list">
                        <thead class="hidden">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{ $t('date') }}</th>
                                <th scope="col">{{ $t('type') }}</th>
                                <th scope="col">{{ $t('giver id') }}</th>
                                <th scope="col">{{ $t('previous balance') }}</th>
                                <th scope="col">{{ $t('inccured amount') }}</th>
                                <th scope="col">{{ $t('current balance') }}</th>
                                <th scope="col">{{ $t('points balance') }}</th>
                                <th scope="col">{{ $t('receiver id') }}</th>
                                <!-- <th scope="col">{{ $t('note') }}</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(r, i) in data.list" :key="r._id">
                                <td class="text-center">
                                    {{
                                        i +
                                        1 +
                                        (data.current_page - 1) * data.per_page
                                    }}
                                </td>
                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                                <td class="text-center">
                                    <div v-if="r.bet"> {{ $t(r.bet.game=='slot'?'slot':'casino')}}</div>
                                    <div v-else>
                                        <span v-if="['add-balance','subtract-balance'].includes(r.type)">
                                            {{ $t(r.type.replace('-', `-${r.role}-`)) }}
                                        </span>
                                        <span v-else-if="['deposit','withdraw'].includes(r.type)">
                                            {{ $t(r.role +'-'+ r.type) }}
                                        </span>
                                        <span v-else> {{$t(r.type)}}</span>
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ 
                                        ![
                                            'claim-coupon',
                                            'player-deposit',
                                            'player-withdraw',
                                        ]
                                        .includes(r.type) &&
                                        r['created_by']?._id != r['transaction']?.user_id  ? 
                                        r['created_by']?.username: '' 
                                    }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.column=='points'?r.initial_points:r.initial_value) }} 
                                </td>
                                <td class="text-end" >
                                    <span v-if="r.column =='points'" :class="{'text-primary':r.points_amount>0,'text-danger':r.points_amount<0}">
                                    {{ numberString(r.points_amount) }} 
                                    </span>
                                    <span v-else :class="{'text-primary':r.amount>0,'text-danger':r.amount<0}">
                                    {{ numberString(r.amount) }} 
                                    </span>
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.column=='points'?r.updated_points:r.updated_value) }} 
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.updated_points) }}
                                </td>
                                <td class="text-start">
                                    <div class="td-username">
                                        <a
                                            v-if="r.user"
                                            href="javascript:void(0)"
                                            @click="
                                                goToTicketForm(
                                                    r.user.username,
                                                    r.role
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>

                                        <a
                                            v-if="r.user"
                                            :title="$t(`click to view`)"
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(
                                                    r.user_id,
                                                    r.role
                                                )
                                            "
                                            class="link-username"
                                            >
                                              <i
                                                :class="`mdi ${
                                                    r.role == 'agent'
                                                        ? 'mdi-account-tie text-danger'
                                                        : r.role == 'player'
                                                        ? 'mdi-account-cowboy-hat text-primary'
                                                        : ''
                                                } fs-5`"></i
                                            >
                                            <span v-if="r.role=='agent'" :class="`lvl lvl-${r.user[r.role].level}`">{{$t(`level ${r.user[r.role].level}`)}}</span>
                                            <i v-else :class="`mdi mdi-numeric-${r.user[r.role].level}-box level-${r.user[r.role].level} fs-5`"></i>{{ r.user.username }}</a>
                                    </div>
                                </td>
                                <!-- <td>{{r.note}}</td> -->
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                </div>
                <Pagination v-if="!loading" :data="data" @emitPage="initList" />
            </div>
        </div>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import MoneyManagementTabs from '@/components/money-management-tabs';

import { mapActions, mapGetters } from 'vuex';
import random from '../../../mixins/random';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';
import BatchForm from '../player/batch-form.vue';
export default {
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
        MoneyManagementTabs,
        BatchForm,
    },
    mixins: [datetime, number, random, ui],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                limit: 50,
                from: new Date(this.firstDateOftheMonth()),
                to: new Date(),
                search: null,
                username: null,
                with_bets: false,
                type: null,
                column: this.$route.params.column,
                role: this.$route.params.role,
            },
            column_options: [
                { name: this.$t('points'), value: 'points' },
                { name: this.$t('balance'), value: 'balance' },
                { name: this.$t('commissions'), value: 'commissions' },
            ],
            type_options: [
                { name: this.$t('add-balance'), value: 'add-balance' },
                { name: this.$t('subtract-balance'), value: 'subtract-balance' },
                { name: this.$t('add-points'), value: 'add-points' },
                { name: this.$t('subtract-points'), value: 'subtract-points' },
                { name: this.$t('add-commissions'), value: 'add-commissions' },
                { name: this.$t('subtract-commissions'), value: 'subtract-commissions' },
                { name: this.$t('player-deposit'), value: 'player-deposit' },
                { name: this.$t('player-withdraw'), value: 'player-withdraw' },
                { name: this.$t('claim-coupon'), value: 'claim-coupon' },
                { name: this.$t('convert-points'), value: 'convert-points' },
                { name: this.$t('convert-commissions'), value: 'convert-commissions' },
            ],
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            loading: false,
        };
    },
    watch: {
        '$store.state.key'() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('log', {
            assetGetList: 'getList',
            getAssetLogDetailed: 'getAssetLogDetailed',
        }),
        async initList(p) {
            this.resetScroll();
            var pl = {
                limit: this.filters.limit,
                page_no: p ? p : 1,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                search: this.filters.search,
                type: this.filters.type,
                with_bets: !this.filters.with_bets
            }; 
            this.loading = true;
            const data = await this.getAssetLogDetailed(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
            this.data.total = data.total;
            this.loading = false;
        },
        resetFilter() {
            this.filters.column = null;
            // this.filters.role = null;
            this.filters.username = null;
            this.filters.search = null;
            this.filters.type = null;
            this.filters.with_bets = false;
            this.initList(1);
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
            this.initList(1);
        },
    },
    mounted() {
        this.$watch(
            () => this.$route.params,
            (n, o) => {
                if (this.$route.query.window) {
                    this.readQuery();
                    this.initList(1);
                } else {
                    if (this.$route.name == 'asset-logs') this.initList(1);
                }
            }
        );
        this.initList(1);
    },
};
</script>
